import React from 'react';
import ReactDOM from 'react-dom/client';

export function registerReusableComponent(Component, name) {
  const existingDefinition = customElements.get(name);
  if (!existingDefinition) {
    customElements.define(
      name,
      class extends HTMLElement {
        constructor() {
          super();
          this.root = ReactDOM.createRoot(this);
        }

        connectedCallback() {
          const text = this.innerText;
          this.root.render(
            <React.StrictMode>
              <Component {...this.getAttributeNames().reduce((acc, key) => ({ ...acc, [key]: this.getAttribute(key) }), {})}>
                {text}
              </Component>
            </React.StrictMode>,
          );
        }

        disconnectedCallback() {
          this.root.unmount();
        }
      },
    );
  }
}
