import { useEffect } from 'react';
import { useReCache } from '@medifind/hooks';
import { getCodingByID, getConditionBySlug } from '@medifind/interface';
import { toSlug, useRouter } from '@medifind/router';
import { toProperLowerCase } from '@medifind/utils';
import { setAdditionalRouteParams, useRouteStore } from '@medifind/zustand';

export const useConditionParams = (params) => {
  const router = useRouter();
  const { routeUUID } = useRouteStore();
  const { projectId, codingSlug } = { ...router.query, ...(params || {}) };

  const conditionBySlug = useReCache(
    'conditionBySlug',
    async () => {
      return getConditionBySlug(codingSlug).catch(() => ({}));
    },
    [codingSlug, projectId],
    [codingSlug, projectId ? null : true],
  );

  /** *****************************************************
   * START CONDITION - Get data for condition name from by project id
   */
  const coding = useReCache(
    'coding',
    async () => {
      return getCodingByID(projectId).catch(() => '');
    },
    [projectId],
    [projectId],
  );

  useEffect(() => {
    if (conditionBySlug) {
      setAdditionalRouteParams(
        {
          codingName: conditionBySlug.display,
          lowerCaseCodingName: toProperLowerCase(conditionBySlug.display),
          projectId: conditionBySlug.projectId,
          codingSlug,
          codingType: conditionBySlug.codingType,
        },
        routeUUID,
      );
    } else {
      if (coding === undefined) {
        setAdditionalRouteParams({ codingNotFound: true }, routeUUID);
      } else {
        if (coding) {
          setAdditionalRouteParams(
            {
              projectId,
              codingName: coding.display,
              isRareCondition: coding.isRareCondition,
              lowerCaseCodingName: toProperLowerCase(coding.display),
              codingSlug: toSlug(coding.display),
              codingType: coding.codingType,
            },
            routeUUID,
          );
        }
        if (coding?.codingType === 'procedure') {
          return router.history.replace(router.location.pathname.replace('conditions', 'procedures'));
        }
      }
    }
  }, [coding, conditionBySlug, routeUUID, codingSlug]);
  /** *****************************************************
   * END CONDITION
   */

  return null;
};
