import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lockContext } from './useLocationWithConfirmation';

// Use this hook mark set the confirmation message on a current route

export const useLinkConfirmation = ({
  lock = false, // Whether or not to display the dialog
  title, // Dialog title
  message, // Dialog message
  continueButtonText, // The text for the continue button
  cancelButtonText, // The text for the cancel button
  onContinue, // Called on cancel click, is async friendly
  onCancel, // Called on cancel click, is async friendly
  continueOnCancel = false, // if true call on cancel but continue the navigation
  style = {},
  classes = {},
  dependencies = [],
}) => {
  const ctx = useContext(lockContext);

  useEffect(() => {
    ctx.linkLock = lock;
    ctx.title = title;
    ctx.message = message;
    ctx.continueButtonText = continueButtonText;
    ctx.cancelButtonText = cancelButtonText;
    ctx.onContinue = onContinue;
    ctx.onCancel = onCancel;
    ctx.continueOnCancel = continueOnCancel;
    ctx.style = style;
    ctx.classes = classes;

    return () => {
      ctx.linkLock = false;
      ctx.message = null;
      ctx.continueButtonText = null;
      ctx.cancelButtonText = null;
      ctx.onContinue = null;
      ctx.onCancel = null;
      ctx.continueOnCancel = false;
      ctx.style = {};
      ctx.classes = {};
    };
  }, [message, lock, ...dependencies]);
};
useLinkConfirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  continueButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  lock: PropTypes.bool,
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
  continueOnCancel: PropTypes.bool,
  style: PropTypes.object,
  classes: PropTypes.object,
};
