import { useEffect } from 'react';

export const useInjectStyles = (injectStyle) => {
  const id = 'mf-inject-styles';

  useEffect(() => {
    let styleElem = document.getElementById(id);
    if (styleElem) {
      styleElem.remove();
    }

    if (!injectStyle) return;

    styleElem = document.createElement('style');
    styleElem.setAttribute('id', id);
    styleElem.setAttribute('type', 'text/css');
    styleElem.innerHTML = injectStyle;

    document.head.appendChild(styleElem);
  }, [injectStyle]);
};
