import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { sessionStorageMF } from '../utils';

const initialState = {
  agreedToTerms: false,
};
const storeName = 'widgetInfusionCenterSearch';
export const useWidgetInfusionCenterSearch = create(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        set,
        reset: () => set({ ...initialState }, false, { type: 'reset' }),
        getInitialState: () => ({ ...initialState }),
      }),
      {
        name: `${process.env.NX_APP}-${storeName}`,
        version: 0,
        storage: createJSONStorage(() => sessionStorageMF),
      },
    ),
    {
      name: process.env.NX_APP,
      store: storeName,
    },
  ),
);

export const setAgreedToICTerms = () => {
  useWidgetInfusionCenterSearch.setState(
    {
      agreedToICTerms: true,
    },
    false,
    {
      type: 'setAgreedToICTerms',
    },
  );
};
