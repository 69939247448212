import { colorLookup } from '@medifind/utils';

import { SVG } from './SVG';
const IconRightCaret = (props) => {
  const { color } = props;
  const colorCode = colorLookup(color) ?? colorLookup('white');
  return (
    <SVG width="7" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1.50696L6.01389 6.49303L1.02782 11.5069"
        stroke={colorCode}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SVG>
  );
};

export { IconRightCaret as default, IconRightCaret };
