import { useEffect } from 'react';
import { getWidgetConfig } from '@medifind/interface';
import { initTagManager } from '@medifind/zustand';

const HostedWidgetWrapper = ({ apiKey, children }) => {
  useEffect(() => {
    getWidgetConfig({ apiKey }).then((data) => {
      initTagManager({
        originalLocation:
          document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search,
        hostedWidgetName: data?.name,
      });
    });
  }, [apiKey]);

  return children;
};

export { HostedWidgetWrapper as default, HostedWidgetWrapper };
